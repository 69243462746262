import React from 'react';
import './article.css';

const Article = ({article}) => (
    <a className="gpt3__blog-container_article" href={article.href}>
        <div className="gpt3__blog-container_article-image">
            <img src={article.image} alt={article.title}/>
        </div>
        <div className="gpt3__blog-container_article-content">
            <div>
                <p>{article.date}</p>
                <h3>{article.title}</h3>
            </div>
            <p >Read Full Article</p>
        </div>
    </a>
);

export default Article;
