import React from 'react';
import './blog.css';

import articles from "./articles";
import {Article} from "../../components";

const Blog = (props) => (
    <div id="blog" className="gpt3__blog section__padding">
        <div className="gpt3__blog-heading">
            <h1 className="gradient__text">A lot is happening, We are blogging about it.</h1>
        </div>
        <div className="gpt3__blog-container">
            <div className="gpt3__blog-container__groupA">
                <Article article={articles.A}/>
            </div>
            <div className="gpt3__blog-container__groupB">
                {articles.B.map((article, index) => <Article key={index} article={article}/>)}
            </div>
        </div>
    </div>
);

export default Blog;
