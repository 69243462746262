import blog01 from '../../assets/blog01.png';
import blog02 from '../../assets/blog02.png';
import blog03 from '../../assets/blog03.png';
import blog04 from '../../assets/blog04.png';
import blog05 from '../../assets/blog05.png';

const articles = {
    A: {
        date: 'Sep 26, 2021',
        title:'GPT-3 and Open  AI is the future. Let us exlore how it is?',
        image: blog01,
        href:'#'
    },
    B: [
        {
            date: 'Sep 26, 2021',
            title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
            image: blog02,
            href: '#'
        },
        {
            date: 'Sep 26, 2021',
            title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
            image: blog03,
            href: '#'
        },
        {
            date: 'Sep 26, 2021',
            title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
            image: blog04,
            href: '#'
        },
        {
            date: 'Sep 26, 2021',
            title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
            image: blog05,
            href: '#'
        },
    ]
};

export default articles;
