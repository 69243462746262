import React from 'react';
import './footer.css';
import logo from '../../assets/logo.svg';


const lists = [
    {
        name: 'Links',
        elements: [
            {title: 'Overons', href: '#Overons'},
            {title: 'Social Media', href: '#Social_Media'},
            {title: 'Counters', href: '#Counters'},
            {title: 'Contact', href: '#Contact'}
        ]
    },
    {
        name: 'Company',
        elements: [
            {title: 'Terms & Conditions', href: '#Terms_Conditions'},
            {title: 'Privacy Policy', href: '#Privacy_Policy'},
            {title: 'Contact', href: '#Contact'},
        ]
    },
    {
        name: 'Get in touch',
        elements: [
            {title: 'Crechterwoord K12 182 DK Alknjkcb', href: '#Crechterwoord_K12_182_DK_Alknjkcb'},
            {title: '085-132567', href: '#085-132567'},
            {title: 'info@payme.net', href: '#info@payme.net'}
        ]
    }
];

const Footer = (props) => (
    <div id="footer" className="gpt3__footer section__padding">
        <div className="gpt3__footer-heading">
            <h1 className="gradient__text">
                Do you want to step in to the future before others
            </h1>
        </div>
        <div className="gpt3__footer-btn">
            <button type="button">Request Early Access</button>
        </div>
        <div className="gpt3__footer-content">
            <div className="gpt3__footer-content-image">
                <img src={logo} alt="gpt3 logo"/>
                <p>Crechterwoord K12 182 DK Alknjkcb, All Rights Reserved</p>
            </div>
            <div className="gpt3__footer-content__lists">
                {lists.map((list, index) => (
                    <div key={list.name + index} className="gpt3__footer-content__lists-container">
                        <h5>{list.name}</h5>
                        <div className="gpt3__footer-content__lists-content">
                            {list.elements.map(element => (
                                <a key={element.title} href={element.href}>{element.title}</a>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="gpt3__footer-copyright">
            <p>© 2021 GPT-3. All rights reserved.</p>
        </div>
    </div>
);

export default Footer;
